import React, { FC, useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import logoWhite from "../../assets/brand/logo-new.svg";
import logoText from "../../assets/brand/logo-new-text.svg";
import { Pathname } from "../../resources/links";

import Button from "../../components/button/Button";
import { HORIZONTAL_PADDING } from "../../styles/global";
import Close from "../icons/Close";
import MenuIcon from "../icons/MenuIcon";
import MobileMenu from "./MobileMenu";

gsap.registerPlugin(ScrollTrigger);

type Props = {
  location: any;
};

const Nav: FC<Props> = ({ location }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const logoRef = useRef<HTMLImageElement | null>(null);
  const [isLogoWhite, setIsLogoWhite] = useState(true);

  useEffect(() => {
    let timeline: GSAPTimeline;

    const setupTimeline = () => {
      timeline = gsap.timeline({
        scrollTrigger: {
          start: "top 20",
          end: "top 30",
          toggleActions: "play none none reverse",
        },
      });

      timeline.to("#nav-bg", {
        opacity: 1,
        duration: 0.3,
      });

      timeline.to(
        logoRef.current, // Use logoRef to target the logo element
        {
          attr: { src: logoWhite }, // Change the src attribute to logoText
          duration: 0.3,
          onComplete: () => setIsLogoWhite(true), // Update state when logo is set to white
        },
        0
      );
    };

    setupTimeline();

    return () => {
      timeline?.kill();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isAtTop = window.scrollY === 0; // Check if the user is at the top of the page

      // Change the logo based on scroll position
      if (isAtTop) {
        setIsLogoWhite(true); // Set to logoWhite at the top
      } else {
        setIsLogoWhite(false); // Set to logoText when scrolled down
      }
    };

    // Add a scroll event listener to detect scroll position
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  return (
    <>
      <nav
        className={`fixed top-0 w-full flex z-[999] justify-between py-2 md:py-4 flex-wrap ${HORIZONTAL_PADDING} text-offWhite`}
      >
        <div
          id="nav-bg"
          className="absolute inset-0 dark-glass glass-shadow-bottom-dark opacity-0"
        />

        <div className="flex items-center w-full justify-between">
          <Link to="/" className="relative flex items-center">
            <img
              ref={logoRef}
              src={isLogoWhite ? logoWhite : logoText}
              alt="Scalar North Capital"
              className={isLogoWhite ? "md:h-full h-14" : "md:h-6 h-2"}
            />
          </Link>

          <div
            className={`hidden lg:flex relative text-sm items-center justify-end uppercase font-light gap-4`}
          >
            <div className="flex items-center gap-4">
              <Link to={Pathname.Home} className={LINK_CLASSES}>
                Home
              </Link>
              <Link to={Pathname.About} className={LINK_CLASSES}>
                About
              </Link>
              <Link to={Pathname.Portfolio} className={LINK_CLASSES}>
                Portfolio
              </Link>
              <Link to={Pathname.News} className={LINK_CLASSES}>
                News
              </Link>
              <Link to={Pathname.Consultancy} className={LINK_CLASSES}>
                Consultancy
              </Link>
              <a
                href={Pathname.Typeform}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  label="Apply for funding"
                  variant="cyan"
                  className="w-auto h-auto"
                />
              </a>
            </div>
          </div>

          <button
            className="relative lg:hidden p-2"
            onClick={() => setOpenMenu((prev) => !prev)}
          >
            {openMenu ? <Close /> : <MenuIcon />}
          </button>
        </div>
      </nav>
      <MobileMenu show={openMenu} dismiss={() => setOpenMenu(false)} />
    </>
  );
};

export default Nav;

// Nav Links component also used inside the Mobile Menu
const LINK_CLASSES =
  "hover:text-cyan md:hover:opacity-100 md:opacity-60 tracking-wide";

export const NavLinks: FC<{ onLinkClick?: () => void }> = ({
  onLinkClick = () => {},
}) => {
  return (
    <>
      <Link
        to={Pathname.Home}
        className={LINK_CLASSES}
        activeStyle={{ opacity: 1 }}
        onClick={onLinkClick}
      >
        Home
      </Link>
      <Link
        to={Pathname.About}
        className={LINK_CLASSES}
        activeStyle={{ opacity: 1 }}
        onClick={onLinkClick}
      >
        About
      </Link>
      <Link
        to={Pathname.Portfolio}
        className={LINK_CLASSES}
        activeStyle={{ opacity: 1 }}
        onClick={onLinkClick}
      >
        Portfolio
      </Link>
      <Link
        to={Pathname.News}
        className={LINK_CLASSES}
        activeStyle={{ opacity: 1 }}
        onClick={onLinkClick}
      >
        News
      </Link>
      <Link
        to={Pathname.Consultancy}
        className={LINK_CLASSES}
        activeStyle={{ opacity: 1 }}
        onClick={onLinkClick}
      >
        Consultancy
      </Link>
      <a
        href={Pathname.Typeform}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onLinkClick}
      >
        <Button label="Apply for funding" variant="cyan" />
      </a>
    </>
  );
};
