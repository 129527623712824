import { useState } from "react";
import { useLocalStorage } from "@mantine/hooks";

export default function useMailSubscribe() {
  const [hasSubscribed, setHasSubscribed] = useLocalStorage({
    key: "hasSubscribed",
    defaultValue: false,
  });
  const [email, setEmail] = useState("");

  const onSubmit = async () => {
    const body = JSON.stringify({ email: email });
    try {
      const res = await fetch("/api/mailchimp", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: body,
      });
      // const data = await res.json();
      setHasSubscribed(true);
      setEmail("Thank you!");
    } catch (e) {
      console.error(e);
    }
  };

  return {
    email,
    hasSubscribed,
    setEmail,
    onSubmit,
  };
}
