import React, { FC } from "react";

const MenuIcon: FC = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_659_956"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.622314" y="0.667236" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_659_956)">
        <path
          d="M3.87231 18.2922V16.7922H21.3723V18.2922H3.87231ZM3.87231 13.4172V11.9172H21.3723V13.4172H3.87231ZM3.87231 8.54224V7.04224H21.3723V8.54224H3.87231Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
