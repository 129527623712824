import React from "react";
import type { FC, PropsWithChildren } from "react";
import gsap from "gsap";
import { PageProps } from "gatsby";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import SplitText from "gsap/dist/SplitText";

import Nav from "../../components/nav/Nav";
import Footer from "../../components/footer/Footer";

type Props = PropsWithChildren<PageProps>;

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, SplitText);

const Layout: FC<Props> = ({ children, location }) => {
  return (
    <>
      <Nav location={location} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
