export const LEFT_PADDING = "pl-8 md:pl-16 lg:pl-24 xl:pl-[8vw] 2xl:pl-[12vw]";
export const RIGHT_PADDING = "pr-8 md:pr-16 lg:pr-24 xl:pr-[8vw] 2xl:pr-[12vw]";

export const HORIZONTAL_PADDING =
  "w-full px-8 md:px-16 lg:px-24 xl:px-[8vw] 2xl:px-[12vw]";

export const H1_ClASSES =
  "text-white font-bold text-4xl md:text-6xl xl:text-7xl";

export const H2_CLASSES =
  "text-white font-bold text-3xl lg:text-5xl xl:text-6xl";

export const H3_CLASSES =
  "text-white font-bold text-3xl md:text-4xl xl:text-5xl font-medium leading-normal";

export const H4_CLASSES =
  "text-white font-bold text-2xl md:text-3xl xl:text-4xl font-medium leading-normal";

export const OVERLINE_CLASSES =
  "block text-lg md:text-[28px] lg:text-[32px] uppercase font-extralight tracking-widest";

export const OVERLINE_SMALL_CLASSES =
  "block text-base lg:text-xl uppercase font-extralight tracking-wide";

export const P_CLASSES =
  "font-light leading-relaxed text-base md:text-lg opacity-70";

export const SECTION_CLASSES =
  "relative w-screen md:h-[75vh] h-[100vh] md:max-[720px] lg:max-h-[800px]" +
  HORIZONTAL_PADDING;

export const DARK_GLASS_CARD_CLASSES = `dark-glass glass-shadow-dark-top max-w-md rounded-lg`;

export const TEXT_CYAN_GRADIENT =
  "text-transparent bg-clip-text bg-gradient-to-r from-cyan to-cyanLight";

export const TEXT_ORANGE_GRADIENT =
  "text-transparent bg-clip-text bg-gradient-to-r from-orange to-orangeLight";
