import React, { type FC } from "react";
import { Link } from "gatsby";
import {
  H4_CLASSES,
  HORIZONTAL_PADDING,
  OVERLINE_SMALL_CLASSES,
} from "../../styles/global";

import logoWhite from "../../assets/brand/logo-new.svg";
import tick from "../../assets/icons/tick.svg";
import useMailSubscribe from "../../hooks/useMailSubscribe";

const DIVIDER_CLASS =
  "ml-1 mr-1 md:h-5 md:w-[1px] h-[1px] w-[90px] bg-cyanExited text-white";

const FOOTER_LINKS = "py-2 hover:text-cyan";

const EmailSubscription: FC = () => {
  const { email, setEmail, hasSubscribed, onSubmit } = useMailSubscribe();

  return (
    <div className="relative flex items-center">
      <input
        type="email"
        name="email"
        className="py-2 px-4 w-full md:text-base text-sm border border-cyanExited rounded-md"
        placeholder="name@email.com"
        disabled={hasSubscribed}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onSubmit={onSubmit}
      />
      {!hasSubscribed && (
        <button className={"w-12 h-12 absolute right-0"} onClick={onSubmit}>
          <img src={tick} alt="Checkmark" />
        </button>
      )}
    </div>
  );
};

const Footer: FC = () => {
  return (
    <footer className={`relative w-full bg-darkBackground`}>
      {/* Wrapper with padding */}
      <div className="relative w-full px-4 md:px-16 lg:px-24 xl:px-[8vw] 2xl:px-[12vw]">
        {/* Left column */}
        <div className="flex">
          {/* Logo */}
          <div className="bg-black/30 w-full md:w-fit px-4 md:py-22 md:px-8 lg:px-12 py-16">
            <img
              src={logoWhite}
              alt="Scalar North Capital"
              className="md:w-[90%] mb-8"
            />
            {/* Email subscribe */}
            <h4 className="text-sm md:text-base text-white font-light mb-2 mt-8">
              Don't miss the latest news and opportunities
            </h4>
            <EmailSubscription />

            {/* Site Links */}
            <div className="flex space-x-4 mt-8 md:test-base text-sm text-zinc-400">
              <div className="flex flex-col space-y-2 font-light">
                <Link to="/" className={`${FOOTER_LINKS}`}>
                  Home
                </Link>
                <Link to="/portfolio" className={`${FOOTER_LINKS}`}>
                  Portfolio
                </Link>
                <a
                  href="https://teqden.com/"
                  target="_blank"
                  rel="noreferrer"
                  className={`${FOOTER_LINKS}`}
                >
                  TeqDen
                </a>
                <Link to="/contact" className={`${FOOTER_LINKS}`}>
                  Contact
                </Link>
              </div>
              <div className="flex flex-col space-y-2">
                <Link to="/about" className={`${FOOTER_LINKS}`}>
                  About
                </Link>
                <Link to="/news" className={`${FOOTER_LINKS}`}>
                  News
                </Link>
                <Link to="/consultancy" className={`${FOOTER_LINKS}`}>
                  Consultancy
                </Link>
              </div>
            </div>
          </div>
          {/* Right column Slogan */}
          <div className="flex-1 md:flex flex-col items-end justify-center hidden">
            <div
              className={`${OVERLINE_SMALL_CLASSES} tracking-wider flex md:flex-row items-center flex-col text-cyanExited mb-6 md:mb-2 text-right opacity-50`}
            >
              <span>Investing</span>
              <div className={`${DIVIDER_CLASS}`} />
              <span>Incubating</span>
              <div className={`${DIVIDER_CLASS}`} />
              <span>Mentoring</span>
              <div className={`${DIVIDER_CLASS}`} />
              <span>Accelerating</span>
            </div>
            <span
              className={`${H4_CLASSES} text-center md:text-right  md:mb-8 max-w-full md:max-w-3xl`}
            >
              Powering the future of technology
            </span>
          </div>
        </div>
      </div>

      <div
        className={`relative flex w-full bg-black py-4 ${HORIZONTAL_PADDING} `}
      >
        <span className="text-xs text-zinc-500">
          <span className="text-white block mb-2">
            © 2023 Copyright Scalar North Capital Limited.
          </span>{" "}
          Company registered in England and Wales: 12507895. VAT registration
          number: 421664315{" "}
          <span className="block mt-1">
            Website by{" "}
            <span className="italic">
              <a href="https://loopspeed.co.uk/" target="_blank">
                Loopspeed
              </a>
            </span>
          </span>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
