import React, { type FC } from "react";

const DEFAULT_CLASSES =
  "block uppercase text-center rounded-md md:py-3 md:px-8 py-2 px-6 w-fit text-base font-semibold";

const VARIANT_CLASSES = {
  dark: "text-white dark-glass glass-shadow-bottom-dark hover:text-cyanLight",
  light: "bg-white text-grey-900 drop-shadow-xl hover:text-orange",
  cyan: "bg-gradient-to-r from-cyan to-[#007180] text-white hover:to-[#005B66]",
};

export const BUTTON_CYAN_CLASSES = `${DEFAULT_CLASSES} ${VARIANT_CLASSES.cyan}`;
export const BUTTON_DARK_CLASSES = `${DEFAULT_CLASSES} ${VARIANT_CLASSES.dark}`;
export const BUTTON_LIGHT_CLASSES = `${DEFAULT_CLASSES} ${VARIANT_CLASSES.light}`;

type Props = React.HTMLProps<HTMLButtonElement> & {
  label: string;
  variant?: "dark" | "light" | "cyan";
  className?: string;
};

const Button: FC<Props> = ({
  label,
  variant = "dark",
  className,
  children,
  ...rest
}) => {
  const classes = `${DEFAULT_CLASSES} ${VARIANT_CLASSES[variant]} ${className}`;

  return (
    // @ts-ignore
    <button {...rest} className={classes}>
      {label}
    </button>
  );
};

export default Button;
