import React, { type FC, useRef } from "react";
import gsap from "gsap";
import { Transition } from "react-transition-group";
import { useScrollLock } from "@mantine/hooks";

import { HORIZONTAL_PADDING } from "../../styles/global";
import { NavLinks } from "./Nav";

type Props = {
  show: boolean;
  dismiss: () => void;
};

const MobileMenu: FC<Props> = ({ show, dismiss }) => {
  const [_, setScrollLocked] = useScrollLock();
  const container = useRef<HTMLDivElement>(null);
  const selector = gsap.utils.selector(container);

  const onEnter = () => {
    setScrollLocked(true);
    const linksAndButtons = selector("button, a");
    gsap.set(container.current, { autoAlpha: 0 });
    gsap.set(linksAndButtons, { opacity: 0 });
  };

  const onEntering = () => {
    const linksAndButtons = selector("button, a");
    gsap
      .timeline()
      .to(container.current, {
        autoAlpha: 1,
        delay: 0.1,
        duration: 0.3,
      })
      .to(linksAndButtons, {
        opacity: 1,
        stagger: 0.1,
        duration: 0.7,
        ease: "power2.inOut",
      });
  };

  const onExit = () => {
    gsap.to(container.current, {
      autoAlpha: 0,
      duration: 0.3,
    });
    setScrollLocked(false);
  };

  return (
    <Transition
      appear={show}
      in={show}
      timeout={500}
      mountOnEnter={true}
      unmountOnExit={true}
      onEnter={onEnter}
      onEntering={onEntering}
      onExit={onExit}
    >
      <div
        ref={container}
        className={`dark-glass fixed inset-0 z-[998] ${HORIZONTAL_PADDING} flex flex-col py-16`}
      >
        <nav
          className={`flex flex-1 flex-col items-center justify-center text-lg uppercase text-white gap-8`}
        >
          <NavLinks />
        </nav>
      </div>
    </Transition>
  );
};

export default MobileMenu;
